import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Studies } from "./pages/studies";
import { StudiesCreate } from "./pages/studiesCreate";
import { ReportDetails } from "./pages/reportDetails";
import { Login } from "./pages/login";
import { Signup } from "./pages/signup";
import { Segmentation } from "./pages/segmentation";
import { PrivateRoute } from "./components/privateRoute";
// import { StudyEditDetails } from "./pages/studyEditDetails";
import { MiniDrawer } from "./components/drawer";
import { AccountDetails } from "./pages/accountDetails";
import { AllStudies } from "./pages/allStudies";
import { apiClient } from "./api/base";
import { User } from "./api/generated_api";
import { Preloader } from "./components/preloader";
import EchoGPT from "./pages/echoGPT";

export const App = () => {
  const [user, setUser] = useState<User>({ email: "", first_name: "", last_name: "", is_superuser: false });

  useEffect(() => {
    apiClient.retrieveUser().then((res) => {
      setUser(res.data);
    });
  }, []);

  const withDrawer = (component: any) => {
    if (user.email == "") return <Preloader />
    return <MiniDrawer isSuperUser={user.is_superuser}>{component}</MiniDrawer>;
  };

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/" element={<PrivateRoute component={withDrawer(<Studies />)} />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/studies" element={<PrivateRoute component={withDrawer(<Studies />)} />} />
      {user.is_superuser && (
        <Route path="/all_studies" element={<PrivateRoute component={withDrawer(<AllStudies />)} />} />
      )}
      <Route
        path="/studies/:id"
        element={<PrivateRoute component={withDrawer(<StudiesCreate isSuperuser={user.is_superuser} />)} />}
      />
      <Route path="/report/:id" element={<PrivateRoute component={withDrawer(<ReportDetails />)} />} />
      {/*<Route path="/studies/edit/:id" element={<StudyEditDetails />} />*/}
      <Route path="/account-details" element={<PrivateRoute component={withDrawer(<AccountDetails />)} />} />
      <Route path="/segmentation/:id" element={<PrivateRoute component={withDrawer(<Segmentation />)} />} />
      <Route path="/echogpt/:id" element={<PrivateRoute component={withDrawer(<EchoGPT />)} />} />
    </Routes>
  );
};
