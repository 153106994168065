import React, { useState } from "react";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import { useSnackbar } from "notistack";
import { FormControl, Button, Grid, CircularProgress, TextField } from "@mui/material";
import { Header } from "../../components/header";
import { Footer } from "../../components/footer";
import styles from "../login/login.module.css";

export const Signup = () => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [emailValue, setEmailValue] = useState<string>("");
  const [firstNameValue, setFirstNameValue] = useState<string>("");
  const [lastNameValue, setLastNameValue] = useState<string>("");
  const [passwordValue, setPasswordValue] = useState<string>("");
  const [confirmPasswordValue, setConfirmPasswordValue] = useState<string>("");
  const [showEmailValidation, setShowEmailValidation] = useState<boolean>(false);
  const [showPasswordValidation, setShowPasswordValidation] = useState<boolean>(false);
  const [showConfirmPasswordValidation, setShowConfirmPasswordValidation] = useState<boolean>(false);
  const [showNameValidation, setShowNameValidation] = useState<boolean>(false);
  const [passwordErrorMsg, setPasswordErrorMsg] = useState<string>("");
  const [emailErrorMsg, setEmailErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const emailRegEx =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const passwordRegEx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[^A-Za-z\d])[A-Za-z\d\W]{8,}$/;

  const handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (!emailValue) {
      setShowEmailValidation(true);
      return;
    }
    if (!firstNameValue) {
      setShowNameValidation(true);
      return;
    }
    if (!lastNameValue) {
      setShowNameValidation(true);
      return;
    }
    if (!passwordValue) {
      setShowPasswordValidation(true);
      return;
    }
    if (!confirmPasswordValue) {
      setShowConfirmPasswordValidation(true);
      return;
    }
    if (showConfirmPasswordValidation || showPasswordValidation) {
      return;
    }
    setIsLoading(true);
    axios
      .post(`${process.env.REACT_APP_API_URL}/api/v2/internal/user_registration/`, {
        first_name: firstNameValue,
        last_name: lastNameValue,
        password: passwordValue,
        email: emailValue,
      })
      .then((res) => {
        axios
          .post(`${process.env.REACT_APP_API_URL}/api/token/`, {
            username: emailValue,
            password: passwordValue,
          })
          .then((res) => {
            const token = res?.data?.access;
            if (token) {
              window.localStorage.setItem("token", res?.data?.access);
              navigate("/studies");
            }
          })
          .catch(() => {
            enqueueSnackbar("Email or password is not correct", { variant: "error", preventDuplicate: true });
          });
      })
      .catch(() => {
        enqueueSnackbar("Account was not created", { variant: "error", preventDuplicate: true });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailValue(event.target.value);
    if (event.target.value && !emailRegEx.test(event.target.value)) {
      setEmailErrorMsg("Email is invalid");
      setShowEmailValidation(true);
    } else if (event.target.value && emailRegEx.test(event.target.value)) {
      setEmailErrorMsg("");
      setShowEmailValidation(false);
    }
  };

  const handleFirstNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameValue(event.target.value);
    if (event.target.value) {
      setShowNameValidation(false);
    }
  };

  const handleLastNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameValue(event.target.value);
    if (event.target.value) {
      setShowNameValidation(false);
    }
  };
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordValue(event.target.value);
    if (event.target.value && !passwordRegEx.test(event.target.value)) {
      setPasswordErrorMsg("Password is too weak");
      setShowPasswordValidation(true);
    }
    if (event.target.value && passwordRegEx.test(event.target.value)) {
      setPasswordErrorMsg("");
      setShowPasswordValidation(false);
    }
    if (confirmPasswordValue && confirmPasswordValue !== event.target.value) {
      setShowConfirmPasswordValidation(true);
    }
    if (confirmPasswordValue && confirmPasswordValue === event.target.value) {
      setShowConfirmPasswordValidation(false);
    }
    if (!event.target.value) {
      setPasswordErrorMsg("");
      setShowPasswordValidation(false);
    }
  };

  const handlePasswordConfirmationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPasswordValue(event.target.value);
    if (event.target.value && event.target.value === passwordValue) {
      setShowConfirmPasswordValidation(false);
    }
    if (event.target.value && event.target.value !== passwordValue) {
      setShowConfirmPasswordValidation(true);
    }
    if (!event.target.value) {
      setShowConfirmPasswordValidation(false);
    }
  };

  return (
    <Grid container justifyContent="center" className={styles.wrapper}>
      <Header />
      <Grid item xs={12} className={styles.formWrapper}>
        <form className={styles.form} onSubmit={handleFormSubmit}>
          <h2 className={styles.formHeader}>iCardio.ai sign up</h2>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Enter your email</div>
            <TextField id="email" fullWidth={true} onChange={handleEmailChange} className={styles.formInput} />
            <div className={styles.validationMessage}>{showEmailValidation && emailErrorMsg}</div>
          </FormControl>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Enter your first name</div>
            <TextField id="firstName" fullWidth={true} onChange={handleFirstNameChange} className={styles.formInput} />
            <div className={styles.validationMessage}>{showNameValidation && "First name field is required*"}</div>
          </FormControl>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Enter your last name</div>
            <TextField id="lastName" fullWidth={true} onChange={handleLastNameChange} className={styles.formInput} />
            <div className={styles.validationMessage}>{showNameValidation && "Last name field is required*"}</div>
          </FormControl>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Enter password</div>
            <TextField
              id="password"
              type="password"
              fullWidth={true}
              onChange={handlePasswordChange}
              className={styles.formInput}
            />
            <div className={styles.validationMessage}>{showPasswordValidation && passwordErrorMsg}</div>
          </FormControl>
          <FormControl className={styles.formControl} color="info">
            <div className={styles.inputLabel}>Confirm your password</div>
            <TextField
              id="confirmPassword"
              type="password"
              fullWidth={true}
              onChange={handlePasswordConfirmationChange}
              className={styles.formInput}
            />
            <div className={styles.validationMessage}>
              {showConfirmPasswordValidation && "Passwords should be the same*"}
            </div>
          </FormControl>
          <FormControl className={styles.formControl}>
            <Button type="submit" className={styles.confirmButton} disabled={isLoading}>
              {isLoading ? <CircularProgress className={styles.progressIcon} size={20} /> : "Sign up"}
            </Button>
          </FormControl>
          <div className={styles.linkToLogin}>
            Already have an account?{" "}
            <Link to="/login" className={styles.loginLink}>
              Log in
            </Link>
          </div>
        </form>
      </Grid>
      <Footer />
    </Grid>
  );
};
