import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { CacheProvider } from "@emotion/react";
import { SnackbarProvider } from "notistack";
// import { ThemeProvider } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { StylesProvider } from "@mui/styles";
import createCache from "@emotion/cache";
import { theme } from "./theme";
import "./index.css";
import { App } from "./app";
import { apiClient, SetupInterceptors } from "./api/base";
import * as Sentry from "@sentry/react";
import { Grid, Typography } from "@mui/material";
import { ApiApi, Configuration, Study } from "./api/generated_api";
import { axiosClient } from "./api/api";
const cache = createCache({
  key: "css",
  prepend: true,
});
console.log("process.env.REACT_APP_VERSION", process.env.REACT_APP_VERSION);
if (process.env.REACT_APP_ENV == "staging" || process.env.REACT_APP_ENV == "prod") {
  console.log("Initializing sentry for environment: " + process.env.REACT_APP_ENV);
  Sentry.init({
    dsn: "https://3c8e1ec12f5342c08bdf845c65df063c@o4504936099282944.ingest.sentry.io/4504936158461952",
    integrations: [new Sentry.BrowserTracing()],
    environment: process.env.REACT_APP_ENV,
    tracesSampleRate: 1.0,
  });
}

function NavigateFunctionComponent() {
  const navigate = useNavigate();
  const [ran, setRan] = useState(false);

  if (!ran) {
    SetupInterceptors(navigate);
    setRan(true);
  }
  return <></>;
}

const Footer = () => {
  const [version, setVersion] = useState<string>("");

  useEffect(() => {
    const configuration = new Configuration({
      basePath: process.env.REACT_APP_API_URL,
    });
    const unatuhenticatedApiClient = new ApiApi(configuration, process.env.REACT_APP_API_URL);

    unatuhenticatedApiClient.retrieveVersionIdentifier().then((result) => {
      result.data.version && setVersion(result.data.version);
    });
  }, []);
  return (
    <Grid container justifyContent="center">
      <Typography>
        GUI Version: {process.env.REACT_APP_VERSION}, API version: {version}
      </Typography>
    </Grid>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <CacheProvider value={cache}>
    <StylesProvider>
      <SnackbarProvider
        maxSnack={3}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        autoHideDuration={2000}
        preventDuplicate={true}
      >
        <ThemeProvider theme={theme}>
          <React.StrictMode>
            <BrowserRouter>
              <NavigateFunctionComponent />
              <App />
              <Footer />
            </BrowserRouter>
          </React.StrictMode>
        </ThemeProvider>
      </SnackbarProvider>
    </StylesProvider>
  </CacheProvider>
);
